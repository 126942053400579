import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const Popup = ({ open, title, message, actions, onClose }) => {
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby={title}
    aria-describedby={message}
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      {actions.map((a) => (
        <Button onClick={a.function}>{a.text}</Button>
      ))}
    </DialogActions>
  </Dialog>;
};

export const RawPopup = ({ open, children, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {children}
    </Dialog>
  );
};

export default Popup;
