import { emit } from "../utils/socketio";

export const getUserDetails = async () => {
  const res = await emit("getUserDetails");
  return res;
};

export const getStudents = async () => {
  const res = await emit("getStudents");
  return res;
};

export const getAllReportsForAdmin = async () => {
  const res = await emit("getAllReportsForAdmin");
  return res;
};

export const getAllTestsWithQuestions = async () => {
  const res = await emit("getAllTestsWithQuestions");
  return res;
};
