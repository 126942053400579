import { emit, getSocket } from "../utils/socketio";

export const getTestReport = (testId) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getTestReport", { testId }, (err, data) => {
      if (err) {
        console.log(err);
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const getAllReports = async () => {
  const res = await emit("getAllReports");
  return res;
};
