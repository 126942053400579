import EastIcon from "@mui/icons-material/East";
import { Box, Button, Typography } from "@mui/material";

const TestInstructions = ({ section, onContinue }) => {
  const { title, duration, instructions } = section;
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        flex: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h2" component="div">
        {title}
      </Typography>
      <Typography variant="h6" component="div">
        Duration: {duration} minutes
      </Typography>
      <div
        style={{ height: "auto", width: "80%" }}
        className="test-instructions"
        dangerouslySetInnerHTML={{ __html: instructions }}
      />
      <Button onClick={onContinue} variant="contained" endIcon={<EastIcon />}>
        Proceed
      </Button>
    </Box>
  );
};

export default TestInstructions;
