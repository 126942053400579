import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getAllReports } from "../../actions/report";
import { getAllTopics } from "../../actions/topics";
import { calculateTopicWiseScoresOfTest } from "../../utils/report";
import Loader from "../utils/Loader";

const sampleData = [
  {
    name: "Logical Reasoning",
    previous: 78,
    current: 54,
  },
  {
    name: "Verbal Ability",
    previous: 56,
    current: 42,
  },
  {
    name: "Aptitude",
    previous: 89,
    current: 56,
  },
];

const TopicWiseAggregate = () => {
  const { id: testId } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const data = [];
      const topics = await getAllTopics();

      const topicsMap = topics.reduce((acc, topic) => {
        acc[topic._id] = topic;
        return acc;
      }, {});

      const res = await getAllReports();
      res.forEach(({ test, responses }) => {
        const topicWiseScoresOfTest = calculateTopicWiseScoresOfTest(
          test,
          responses,
          topicsMap
        );
        data.push({
          testId: test._id,
          ...topicWiseScoresOfTest,
        });
      });
      const topicWiseAggregate = [];
      const currentTest = res.find(({ test }) => test._id === testId)?.test;
      const topicsOfTest = currentTest.sections
        .map((s) => s.topicIds)
        .flat()
        .map((tId) => topicsMap[tId]);
      topicsOfTest.forEach((topic) => {
        const currentTestScore = data.find(
          ({ testId: tId }) => tId === testId
        )?.[topic.title];
        const currentTestAverage = currentTestScore?.count
          ? currentTestScore.score / currentTestScore.count
          : null;

        const previousTests = data
          .filter(({ tId }) => tId !== testId)
          .filter((ts) => ts[topic.title]);
        const previousTestsAggregate = previousTests.reduce(
          (acc, { [topic.title]: { score, count } }) => {
            acc.score += score;
            acc.count += count;
            return acc;
          },
          { score: 0, count: 0 }
        );
        const previousTestsAverage =
          previousTestsAggregate.count > 0
            ? previousTestsAggregate.score / previousTestsAggregate.count
            : null;
        topicWiseAggregate.push({
          name: topic.title,
          previous: Math.round(previousTestsAverage * 100) / 100,
          current: Math.round(currentTestAverage * 100) / 100,
        });
      });
      setData(topicWiseAggregate);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        // onClick={(data) => setTopic(data.activeLabel)}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />

        <Bar dataKey="previous" fill="#82ca9d" />
        <Bar dataKey="current" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default TopicWiseAggregate;
