import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  getAllTestsWithQuestions,
  getAllReportsForAdmin,
  getStudents,
} from "../../actions/user";
import { calculateScore } from "../../utils/report";
import Loader from "../utils/Loader";

const AdminReport = () => {
  const [students, setStudents] = useState([]);
  const [reports, setReports] = useState([]);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scoresOfUsers, setScoresOfUsers] = useState({});

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const scoresOfUsers = {};
    Object.keys(reports).forEach((testId) => {
      Object.keys(reports[testId]).forEach((userId) => {
        // calculate score of user for this test
        const test = tests[testId];
        if (!test) return;
        if (!scoresOfUsers[userId]) {
          scoresOfUsers[userId] = {};
        }
        scoresOfUsers[userId][testId] = calculateScore(
          test.sections,
          reports[testId][userId].responses
        );
      });
    });
    setScoresOfUsers(scoresOfUsers);
  }, [tests, reports]);

  const init = async () => {
    const students = await getStudents();
    setStudents(students);
    const allReports = await getAllReportsForAdmin();
    setReports(allReports);
    const allTests = await getAllTestsWithQuestions();
    console.log(allTests);
    setTests(
      allTests.reduce((acc, test) => {
        acc[test._id] = test;
        return acc;
      }, {})
    );
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  const orderedTestIds = Object.keys(tests).sort(
    (a, b) => dayjs(tests[a].startTime) - dayjs(tests[b].startTime)
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Students \ Tests</TableCell>
            {orderedTestIds.map((testId) => (
              <TableCell>{tests[testId].title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student) => {
            const userId = student?._id;
            return (
              <TableRow
                key={userId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {student?.name}
                </TableCell>
                {orderedTestIds.map((testId) => (
                  <TableCell>{scoresOfUsers[userId]?.[testId]}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminReport;
