import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Divider,
  createTheme,
  ThemeProvider,
  Box,
} from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
} from "@mui/lab";
import dayjs from "dayjs";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router";
import { testStatuses } from "../../utils/constants";
import routes from "../Layout/routes";
import { roundTo } from "../../utils/numbers";

const theme = createTheme({
  palette: {
    dark: {
      main: "#333",
      contrastText: "#fff",
    },
  },
});

export const TestSectionsTimeline = ({
  obj,
  compressed = false,
  onViewAll,
}) => {
  const sections = compressed ? obj.sections.slice(0, 2) : obj.sections;
  return (
    <Timeline sx={{ pl: 0, pt: 0, mt: 0, height: 120 }}>
      {sections.map((section, index) => (
        <TimelineItem
          sx={{
            ":before": {
              paddingLeft: 0,
              marginLeft: 0,
              flex: 0,
            },
          }}
          key={index}
        >
          <TimelineSeparator>
            <TimelineDot />
            {index === obj.sections.length - 1 ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1" gutterBottom component="div">
              {section.title}
            </Typography>
            <Typography variant="body2" gutterBottom component="div">
              Duration: {section.duration} minutes
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
      {obj.sections.length > sections.length && (
        <ThemeProvider theme={theme}>
          <Button
            variant="outlined"
            size="small"
            color="dark"
            onClick={onViewAll}
          >
            View All
          </Button>
        </ThemeProvider>
      )}
    </Timeline>
  );
};

export const TestCardActions = ({ obj }) => {
  const navigate = useNavigate();

  return obj.userTestStatus === testStatuses.SUBMITTED ||
    obj.status === testStatuses.FINISHED ? (
    <Button
      size="small"
      endIcon={<ArrowForwardIcon />}
      onClick={() => navigate(routes.testReport.getPath(obj._id))}
    >
      Show Report
    </Button>
  ) : obj.status === testStatuses.IN_PROGRESS &&
    obj.userTestStatus !== testStatuses.SUBMITTED ? (
    <Button
      size="small"
      endIcon={<ArrowForwardIcon />}
      onClick={() => navigate(routes.takeTest.getPath(obj._id))}
      color="error"
    >
      {obj.userTestStatus === testStatuses.IN_PROGRESS ? "Resume" : "Take Test"}
    </Button>
  ) : obj.status === testStatuses.SCHEDULED ? (
    <Typography
      variant="button"
      sx={{ pl: 1, pr: 1, display: "flex", alignItems: "center" }}
    >
      <HistoryToggleOffIcon sx={{ mr: 1 }} fontSize="small" />
      Starts on {dayjs(obj.startTime).format("MMM DD, YYYY hh:mm A")}
    </Typography>
  ) : obj.status === testStatuses.CANCELLED ? (
    <Typography
      variant="button"
      sx={{ pl: 1, pr: 1, display: "flex", alignItems: "center" }}
      color="grey"
    >
      <CancelIcon sx={{ mr: 1 }} fontSize="small" />
      Cancelled
    </Typography>
  ) : null;
};

const TestCard = ({ obj, onExpand }) => {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "fit-content",
          height: "auto",
          overflow: "visible",
          m: 1,
        }}
        variant="outlined"
      >
        <CardContent sx={{ height: 260, pt: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              pt: 0,
            }}
          >
            <Typography variant="h5" component="div">
              {obj.title}
            </Typography>
            <Typography variant="h5" component="div">
              {obj.score?.total > 0
                ? `${roundTo((obj.score.score * 100) / obj.score.total, 1)}%`
                : null}
            </Typography>
          </Box>
          <Divider />
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={obj} compressed onViewAll={onExpand} />
        </CardContent>
        <CardActions sx={{ marginTop: "auto", height: "auto" }}>
          <TestCardActions obj={obj} />
        </CardActions>
      </Card>
    </>
  );
};

export default TestCard;
