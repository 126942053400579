import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { red, yellow } from "@mui/material/colors";

const CodeQuestionReport = ({ index, obj, responses }) => {
  const sortedResponses =
    responses?.sort((a, b) =>
      dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1
    ) || []; // sorted by createdAt with most recent submission at index 0

  let finalResponse;
  const submittedResponses = sortedResponses.filter((r) => r.submitted);
  if (submittedResponses.length === 0) {
    finalResponse = sortedResponses[0];
  } else {
    finalResponse = submittedResponses[0];
  }

  return (
    <Paper
      sx={{ mb: 2, p: 2, display: "flex", flexDirection: "column" }}
      variant="outlined"
    >
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="h6"
          component="div"
          sx={{ lineHeight: 1.5, fontWeight: "400", fontSize: 18 }}
        >
          {index + 1}.
        </Typography>
        <Box sx={{ ml: 1 }}>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ lineHeight: 1.5, fontWeight: "400", fontSize: 18 }}
          >
            {obj.text}
          </Typography> */}
          <div
            style={{ height: "auto", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: obj.text }}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography sx={{ mt: 4 }} variant="body1" component="div">
            Test cases passed:
          </Typography>
          &nbsp;
          <Typography sx={{ mt: 4 }} variant="body1" component="div">
            {!finalResponse
              ? 0
              : finalResponse.testCasesResult.filter((testCase) => testCase)
                  .length}{" "}
            / {finalResponse?.testCasesResult.length}
          </Typography>
        </Box>
        <List>
          {(finalResponse?.testCasesResult || []).map((tc, index) => {
            console.log(tc);
            return (
              <ListItem key={index}>
                <ListItemIcon>
                  {tc === null ? (
                    <CircleOutlinedIcon sx={{ color: yellow[600] }} />
                  ) : tc ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon sx={{ color: red[500] }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={`Test Case ${index + 1}`} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
};

export default CodeQuestionReport;
