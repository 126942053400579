import { Button } from "@mui/material";
import "./styles.css";

const TestFooter = ({ onPrev, onNext, onClearResponse }) => {
  return (
    <div className="test-footer">
      <div className="test-footer-left">
        {onClearResponse && (
          <Button variant="outlined" color="error" onClick={onClearResponse}>
            Clear Response
          </Button>
        )}
      </div>
      <div className="test-footer-right">
        {onPrev && (
          <Button variant="text" onClick={onPrev}>
            Prev
          </Button>
        )}
        {onNext && (
          <Button variant="contained" onClick={onNext}>
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default TestFooter;
