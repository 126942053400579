import { Card } from "@mui/material";
import React from "react";
import { QuestionTypes } from "../../utils/constants";
import CodeQuestion from "./CodeQuestion";
import TextQuestion from "./TextQuestion";

const Question = ({ obj, selectedOption, onMark, marked, onSelect }) => {
  return (
    <Card
      variant="none"
      sx={{
        flex: 1,
        height: "auto",
      }}
    >
      {obj.type === QuestionTypes.MCQ ? (
        <TextQuestion
          obj={obj}
          onMark={onMark}
          marked={marked}
          selectedOption={selectedOption}
          onOptionSelect={onSelect}
        />
      ) : obj.type === QuestionTypes.Code ? (
        <CodeQuestion obj={obj} onMark={onMark} marked={marked} />
      ) : null}
    </Card>
  );
};

export default Question;
