export const testStatuses = {
  SCHEDULED: "SCHEDULED",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
  FINISHED: "FINISHED",
  SUBMITTED: "SUBMITTED",
};

export const testStatusGroup = {
  All: "*",
  Finished: [testStatuses.FINISHED, testStatuses.SUBMITTED],
  Upcoming: [testStatuses.SCHEDULED],
  "In Progress": [testStatuses.NOT_STARTED, testStatuses.IN_PROGRESS],
};

export const QuestionTypes = {
  MCQ: "mcq",
  Code: "code",
};

export const testTypes = {
  PRACTICE: "PRACTICE",
  PREPARATION_PRACTICE: "PREPARATION_PRACTICE",
  COLLEGE: "COLLEGE",
  COMPANY: "COMPANY",
};

export const initialFilters = {
  title: "",
  testStatus: "All",
  date: null,
};

export const RECORDING_STATUS = {
  INITIATED: "INITIATED",
  NOT_SUPPORTED: "NOT_SUPPORTED",
  NOT_ALLOWED: "NOT_ALLOWED",
  ALLOWED: "ALLOWED",
};

export const EXAM_STATUS = {
  INSTRUCTIONS: "INSTRUCTIONS",
  VIDEO_PREVIEW: "VIDEO_PREVIEW",
  TEST: "TEST",
};
