import { QuestionTypes } from "./constants";
import { getCodingQuestionResponse, getMCQQuestionResponse } from "./responses";

export const calculateScore = (sections, responses) => {
  let score = 0;
  const questions = {};
  sections.forEach((section) => {
    section.questions.forEach((question) => {
      questions[question._id] = question;
    });
  });

  Object.keys(responses).forEach((questionId) => {
    const question = questions[questionId];
    switch (question.type) {
      case QuestionTypes.MCQ: {
        const finalResponse = getMCQQuestionResponse(responses[questionId]);
        const correctOption = question.options.find((opt) => opt.isCorrect);
        if (finalResponse.optionId === correctOption._id) score++;
        break;
      }
      case QuestionTypes.Code:
        {
          const finalResponse = getCodingQuestionResponse(
            responses[questionId]
          );
          score += finalResponse
            ? finalResponse.testCasesResult.filter((testCase) => testCase)
                .length / finalResponse.testCasesResult.length
            : 0;
        }
        break;
      default:
        break;
    }
  });
  return `${score} / ${Object.keys(questions).length}`;
};

export const calculateTopicWiseScoresOfTest = (test, responses, topics) => {
  const questionsByTopic = {};
  test.sections.forEach((section) => {
    section.questions.forEach((question) => {
      if (questionsByTopic[question.topicId]) {
        questionsByTopic[question.topicId].push(question);
      } else {
        questionsByTopic[question.topicId] = [question];
      }
    });
  });

  const topicWiseScores = {};
  Object.keys(questionsByTopic).forEach((topicId) => {
    const questions = questionsByTopic[topicId];
    let score = 0;
    questions.forEach((question) => {
      const questionId = question._id;
      if (!responses[questionId]) return;
      switch (question.type) {
        case QuestionTypes.MCQ: {
          const finalResponse = getMCQQuestionResponse(responses[questionId]);
          const correctOption = question.options.find((opt) => opt.isCorrect);
          if (finalResponse.optionId === correctOption._id) score++;
          break;
        }
        case QuestionTypes.Code:
          {
            const finalResponse = getCodingQuestionResponse(
              responses[questionId]
            );
            score += finalResponse
              ? finalResponse.testCasesResult.filter((testCase) => testCase)
                  .length / finalResponse.testCasesResult.length
              : 0;
          }
          break;
        default:
          break;
      }
    });

    topicWiseScores[topics[topicId].title] = {
      score,
      count: questions.length,
    };
  });

  return topicWiseScores;
};
