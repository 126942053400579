import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getAllReports } from "../../actions/report";
import { getAllTopics } from "../../actions/topics";
import { calculateTopicWiseScoresOfTest } from "../../utils/report";
import Loader from "../utils/Loader";

const sampleData = [
  {
    name: "Test 1",
    "Logical Reasoning": 40,
    "Verbal Ability": 24,
    Aptitude: 34,
  },
  {
    name: "Test 2",
    "Logical Reasoning": 30,
    "Verbal Ability": 38,
    Aptitude: 47,
  },
  {
    name: "Test 3",
    "Logical Reasoning": 20,
    "Verbal Ability": 98,
    Aptitude: 54,
  },
  {
    name: "Test 4",
    "Logical Reasoning": 78,
    "Verbal Ability": 38,
    Aptitude: 49,
  },
  {
    name: "Test 5",
    "Logical Reasoning": 34,
    "Verbal Ability": 48,
    Aptitude: 40,
  },
  {
    name: "Test 6",
    "Logical Reasoning": 56,
    "Verbal Ability": 38,
    Aptitude: 56,
  },
  {
    name: "Test 7",
    "Logical Reasoning": 67,
    "Verbal Ability": 43,
    Aptitude: 78,
  },
  {
    name: "Test 8",
    "Logical Reasoning": 57,
    "Verbal Ability": 73,
    Aptitude: 68,
  },
  {
    name: "Test 9",
    "Logical Reasoning": 47,
    "Verbal Ability": 49,
    Aptitude: 98,
  },
  {
    name: "Test 10",
    "Logical Reasoning": 67,
    "Verbal Ability": 45,
    Aptitude: 90,
  },
];

const TopicsWiseScore = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const data = [];
      const topics = await getAllTopics();
      const topicsMap = topics.reduce((acc, topic) => {
        acc[topic._id] = topic;
        return acc;
      }, {});

      const res = await getAllReports();
      const tests = {};
      res.forEach(({ test, responses }) => {
        const topicWiseScores = calculateTopicWiseScoresOfTest(
          test,
          responses,
          topicsMap
        );
        const topicWiseAverageScores = {};
        Object.keys(topicWiseScores).forEach((topic) => {
          topicWiseAverageScores[topic] =
            topicWiseScores[topic].count > 0
              ? Math.round(
                  (topicWiseScores[topic].score * 100) /
                    topicWiseScores[topic].count
                ) / 100
              : 0;
        });
        tests[test._id] = test;
        data.push({
          testId: test._id,
          name: test.title,
          ...topicWiseAverageScores,
        });
      });
      setData(
        data.sort(
          (a, b) =>
            dayjs(tests[a.testId].startTime) - dayjs(tests[b.testId].startTime)
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <ResponsiveContainer width="100%" height={500}>
      <LineChart
        width={730}
        height={250}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line dataKey="Verbal Ability" stroke="#8884d8" strokeWidth={3} />
        <Line dataKey="Logical Reasoning" stroke="#82ca9d" strokeWidth={3} />
        <Line dataKey="Aptitude" stroke="#ca9d" strokeWidth={3} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TopicsWiseScore;
