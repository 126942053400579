import { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import QuestionsReport from "../components/TestReport/QuestionsReport";
import TestAnalytics from "../components/TestReport/TestAnalytics";
import ReportError from "../components/TestReport/ReportError";

function TabPanel(props) {
  const { children, value, current, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== current}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === current && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const TestReport = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [test, setTest] = useState({});
  const [error, setError] = useState(null);

  const tabs = [
    {
      title: "Report",
      Component: (
        <QuestionsReport test={test} setTest={setTest} setError={setError} />
      ),
    },
    {
      title: "Analytics",
      Component: <TestAnalytics />,
    },
  ];

  const changeTab = (event, value) => {
    console.log(value);
    setCurrentTab(value);
  };

  return error ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReportError message={error.message} />
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
        width: "auto",
        height: "auto",
        ml: "auto",
        mr: "auto",
        p: 2,
        pl: theme.spacing(30),
        pr: theme.spacing(30),
        [theme.breakpoints.down("lg")]: {
          pl: theme.spacing(10),
          pr: theme.spacing(10),
        },
        [theme.breakpoints.down("md")]: {
          pl: theme.spacing(5),
          pr: theme.spacing(5),
        },
        [theme.breakpoints.down("sm")]: {
          pl: theme.spacing(2),
          pr: theme.spacing(2),
        },
      })}
    >
      <Typography variant="h5" component="div" gutterBottom>
        Report of {test.title}
      </Typography>
      <Box
        sx={{
          bgcolor: "background.paper",
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <Tabs
          orientation="vertical"
          variant="scrollable"
          value={currentTab}
          onChange={changeTab}
          aria-label="Vertical tabs"
          sx={{ borderRight: 1, borderColor: "divider", width: "20%" }}
        >
          {tabs.map((tab, index) => (
            <Tab key={tab.title} label={tab.title} {...a11yProps(index)} />
          ))}
        </Tabs> */}

        <Box sx={{ ml: "auto", overflowY: "auto", width: "100%" }}>
          {tabs.map(({ title, Component }, index) => (
            <TabPanel
              key={title}
              value={index}
              current={currentTab}
              index={index}
            >
              {Component}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TestReport;
