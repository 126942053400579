import React from "react";
import { QuestionTypes } from "../../utils/constants";
import CodeQuestionReport from "./CodeQuestionReport";
import TextQuestionReport from "./TextQuestionReport";

const QuestionReport = (props) => {
  const { obj } = props;

  switch (obj.type) {
    case QuestionTypes.MCQ:
      return <TextQuestionReport {...props} />;
    case QuestionTypes.Code:
      return <CodeQuestionReport {...props} />;
    default:
      return React.Fragment;
  }
};

const TestSectionReport = ({
  section,
  responses,
  report,
  timeAnalytics,
  hideQueAnalytics,
}) => {
  return section.questions.map((q, index) => (
    <QuestionReport
      key={q._id}
      index={index}
      obj={q}
      responses={responses[q._id]}
      report={report[q._id]}
      timeTaken={timeAnalytics[q._id]?.timeTaken}
      hideQueAnalytics={hideQueAnalytics}
    />
  ));
};

export default TestSectionReport;
