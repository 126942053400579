import Home from "../../pages/Home";
import TakeTest from "../../pages/TakeTest";
import TestReport from "../../pages/TestReport";
import AdminReport from "../TestReport/AdminReport";

const routes = {
  home: {
    title: "Home",
    path: "/",
    component: <Home />,
  },
  takeTest: {
    title: "Take Test",
    _path: "/test",
    get path() {
      return `${this._path}/:id`;
    },
    getPath(id) {
      return `${this._path}/${id}`;
    },
    component: <TakeTest />,
  },
  testReport: {
    title: "Test Report",
    _path: "/test/report",
    get path() {
      return `${this._path}/:id`;
    },
    getPath(id) {
      return `${this._path}/${id}`;
    },
    component: <TestReport />,
  },
  adminReport: {
    title: "Test Report",
    path: "/test/admin-report",
    component: <AdminReport />,
  },
};

export default routes;
