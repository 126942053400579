import EastIcon from "@mui/icons-material/East";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { RECORDING_STATUS } from "../../utils/constants";

const Preview = ({ stream, status, onContinue, onRetry }) => {
  console.log({ stream });
  useEffect(() => {
    const video = document.getElementById("video-preview");
    if (stream) {
      video.srcObject = stream;
      video.muted = true;
      video.onloadedmetadata = () => {
        video.play();
      };
    }

    return () => {
      video.srcObject = null;
    };
  }, [stream]);

  return (
    <Paper
      variant="outlined"
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: theme.spacing(2),
        mt: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
          width: "50%",
        },
      })}
    >
      <Typography variant="h4" gutterBottom>
        Video Preview
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please allow access to your camera and microphone to continue.
      </Typography>
      <Typography variant="body2" gutterBottom>
        You will be able to see yourself before the test starts.
      </Typography>
      <Paper variant="outlined" sx={{ mt: 2 }}>
        <Box display="flex" justifyContent="center">
          <video id="video-preview" width="100%" height="auto" />
        </Box>
        {status === RECORDING_STATUS.INITIATED ? (
          <Typography variant="body2">Initializing...</Typography>
        ) : status === RECORDING_STATUS.NOT_SUPPORTED ? (
          <Typography variant="body2" color="secondary">
            Your browser does not support video recording. Please try another
            browser.
          </Typography>
        ) : status === RECORDING_STATUS.NOT_ALLOWED ? (
          <Typography variant="body2" color="secondary">
            You have denied access to the camera and microphone. Please allow
            access to continue.
          </Typography>
        ) : null}
      </Paper>
      <Box sx={{ mt: 2 }}>
        {status === RECORDING_STATUS.ALLOWED ? (
          <Button
            onClick={onContinue}
            variant="contained"
            endIcon={<EastIcon />}
          >
            Continue
          </Button>
        ) : status === RECORDING_STATUS.NOT_ALLOWED ? (
          <Button size="small" onClick={onRetry}>
            Retry
          </Button>
        ) : null}
      </Box>
    </Paper>
  );
};

export default Preview;
