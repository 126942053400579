import { Box, Typography, Paper } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { TestError } from "../../pages/TakeTest";

const ReportError = ({ message }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "90%",
        width: "80%",
        justifyContent: "center",
      }}
    >
      <Paper
        sx={{
          width: "50%",
          height: "20%",
          padding: "8%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
        variant="outlined"
      >
        <AllInclusiveIcon fontSize="large" />
        <TestError message={message} />
      </Paper>
    </Box>
  );
};

export default ReportError;
