import { Button, Card } from "@mui/material";
import { useEffect, useState } from "react";

const FloatingVideo = ({ stream, onLoad }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const video = document.getElementById("video-element");
    if (stream) {
      video.srcObject = stream;
      video.muted = true;
      video.onloadedmetadata = () => {
        video.play();
      };
      setTimeout(() => {
        onLoad(video);
      }, 3000);
    }

    return () => {
      video.srcObject = null;
    };
  }, [stream]);

  return (
    <Card sx={{ width: 300 }} draggable="true">
      <video
        id="video-element"
        width="100%"
        height="auto"
        style={{ display: visible ? "block" : "none" }}
      />
      <Button
        variant="contained"
        color="primary"
        sx={{ width: "100%" }}
        onClick={() => setVisible(!visible)}
      >
        {visible ? "Hide" : "Show"}
      </Button>
    </Card>
  );
};

export default FloatingVideo;
