import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  SwipeableDrawer,
} from "@mui/material";
import Logo from "./Logo";
import { MenuButton } from "./NavBar";

const sidebarItems = {
  apps: [
    { title: "Account", path: process.env.REACT_APP_AUTH_WEB },
    { title: "Study Material", path: process.env.REACT_APP_SM_WEB },
  ],
};

const Sidebar = ({ open, toggleDrawer }) => {
  return (
    <SwipeableDrawer
      anchor="left"
      open={open}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
    >
      <Paper
        elevation={6}
        square
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          padding: 3,
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <MenuButton onClick={toggleDrawer} />
        <Logo />
      </Paper>

      <Box
        sx={{ width: 280, padding: 1, paddingLeft: 0.5 }}
        role="presentation"
        onClick={toggleDrawer}
      >
        <List
          sx={{
            padding: 1,
          }}
          subheader={<ListSubheader>Apps</ListSubheader>}
        >
          {sidebarItems.apps.map(({ title, path }, index) => (
            <a
              key={title}
              href={path}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              <ListItem key={index} disablePadding>
                <ListItemButton>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            </a>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  );
};

export default Sidebar;
