import FlagIcon from "@mui/icons-material/Flag";
import {
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";

const Option = ({ obj }) => {
  return (
    // eslint-disable-next-line
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        value={obj._id}
        control={<Radio />}
        label={
          <div
            style={{ height: "auto", width: "100%" }}
            dangerouslySetInnerHTML={{ __html: obj.text }}
          />
        }
      />
    </Box>
  );
};

const TextQuestion = ({
  obj,
  onMark,
  marked,
  selectedOption,
  onOptionSelect,
}) => {
  return (
    <CardContent>
      <Box sx={{ marginBottom: 2, display: "flex", flexDirection: "row" }}>
        {/* <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "400", fontSize: 18 }}
        >
          {obj.text}
        </Typography> */}
        <div
          style={{ height: "auto", width: "100%" }}
          dangerouslySetInnerHTML={{ __html: obj.text }}
        />
        <Box sx={{ marginLeft: "auto" }}>
          <Tooltip title={marked ? "Unmark" : "Mark for Later"}>
            <IconButton aria-label="flag" onClick={onMark}>
              <FlagIcon
                sx={
                  marked
                    ? { fill: "green", strokeWidth: 1, stroke: "green" }
                    : { fill: "white", stroke: "grey", strokeWidth: "2px" }
                }
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <FormControl>
        <RadioGroup
          value={selectedOption || null}
          onChange={(e) => onOptionSelect(e.target.value)}
        >
          {obj.options.map((opt) => (
            <Option key={opt._id} obj={opt} />
          ))}
        </RadioGroup>
      </FormControl>
    </CardContent>
  );
};

export default TextQuestion;
