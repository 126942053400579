import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../App";
import NavBar from "./NavBar";
import Router from "./Router";
import Sidebar from "./Sidebar";

export default function Layout() {
  const [height, setHeight] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const {
    state: { isFullScreen },
  } = useContext(AuthContext);

  const ref = useRef();

  const toggleDrawer = () => setSidebarOpen(!sidebarOpen);

  useEffect(() => {
    setHeight(ref.current?.offsetHeight);
  }, [ref.current?.offsetHeight]);

  return (
    <>
      <NavBar ref={ref} toggleDrawer={toggleDrawer} />
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} />
      <Box
        sx={{
          width: "100%",
          height: `100%`,
          overflowY: "scroll",
          mt: isFullScreen ? 0 : `${height}px`,
        }}
      >
        <Router />
      </Box>
    </>
  );
}
