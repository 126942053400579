import { Grid, Typography } from "@mui/material";
import "./styles.css";

const BLOCK_VALUE = {
  null: 0,
  answered: 1,
  marked: 2,
  current: 4,
};

const Block = ({ text, onClick, marked, answered, isCurrentQuestion }) => {
  return (
    <div
      className={
        "question-block " +
        labels[answered + marked].className +
        " " +
        (isCurrentQuestion ? labels[BLOCK_VALUE.current].className : "")
      }
      onClick={onClick}
    >
      <div className="question-block-text">{text}</div>
    </div>
  );
};

const Label = ({ text, className }) => {
  return (
    <div className="label">
      <div className={"label-color " + className}></div>
      <div className="label-text">{text}</div>
    </div>
  );
};

const labels = {
  [BLOCK_VALUE.answered]: {
    text: "Answered",
    className: "question-block-green",
  },
  [BLOCK_VALUE.answered + BLOCK_VALUE.marked]: {
    text: "Answered but Marked",
    className: "question-block-yellow",
  },
  [BLOCK_VALUE.marked]: {
    text: "Marked for later",
    className: "question-block-red",
  },
  [BLOCK_VALUE.null]: {
    text: "Not answered",
    className: "question-block-grey",
  },
  [BLOCK_VALUE.current]: {
    text: "Current Question",
    className: "question-block-border-blue",
  },
};

const QuestionGrid = ({
  questionsCount,
  onQuestionClick,
  answeredQuestions,
  markedQuestions,
  questionIds,
  currentQuestion,
}) => {
  return (
    <div>
      <Typography variant="h6" component="p">
        Questions
      </Typography>
      <Grid container spacing={1}>
        {questionIds.map((qId, index) => (
          <Block
            key={qId}
            text={index + 1}
            onClick={() => onQuestionClick(index)}
            answered={
              answeredQuestions.has(qId)
                ? BLOCK_VALUE.answered
                : BLOCK_VALUE.null
            }
            marked={
              markedQuestions.has(qId) ? BLOCK_VALUE.marked : BLOCK_VALUE.null
            }
            isCurrentQuestion={currentQuestion === index}
          />
        ))}
      </Grid>
      <div className="labels-container">
        {Object.values(labels).map((label, index) => (
          <Label key={index} className={label.className} text={label.text} />
        ))}
      </div>
    </div>
  );
};

export default QuestionGrid;
