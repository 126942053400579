import { createContext, useEffect, useReducer, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { getUserDetails } from "./actions/user";
import Layout from "./components/Layout";
import { getSocket } from "./utils/socketio";

export const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  isFullScreen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
        user: null,
      };
    case "TOGGLE_FULLSCREEN":
      return {
        ...state,
        isFullScreen: !state.isFullScreen,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getUserDetails().then((res) => {
      dispatch({
        type: "LOGIN",
        payload: {
          user: res.user,
        },
      });
    });
  }, []);

  const init = () => {
    const socket = getSocket();
    if (socket.connected) {
      setLoading(false);
      return;
    }
    setTimeout(init, 100);
  };

  return loading ? null : (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
