import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import { initialFilters, testStatusGroup } from "../../utils/constants";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const TestListFilters = ({ filters, onChange, onReset }) => {
  const changeDate = (newValue) => {
    onChange({ date: newValue });
  };
  const changeTestTitle = (e) => {
    onChange({ title: e.target.value });
  };
  const changeTestStatus = (e) => {
    onChange({ testStatus: e.target.value });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="div" gutterBottom>
          Filters
        </Typography>
        <Button disabled={filters === initialFilters} onClick={onReset}>
          Clear All Filters
        </Button>
      </Box>
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          mb: 4,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        })}
      >
        <FormControl fullWidth>
          <TextField
            label="Test Title"
            value={filters.title}
            onChange={changeTestTitle}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="select-test-status">Status</InputLabel>
          <Select
            labelId="select-test-status"
            id="demo-simple-select"
            value={filters.testStatus}
            label="Status"
            onChange={changeTestStatus}
          >
            {Object.keys(testStatusGroup).map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Test Date"
              value={filters.date}
              onChange={changeDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
    </Box>
  );
};

export default TestListFilters;
