import { Alert, AlertTitle, Button } from "@mui/material";
import { RawPopup } from ".";

const CheatingPopup = ({ open, onClose }) => {
  return (
    <RawPopup open={open} onClose={onClose}>
      <Alert
        severity="warning"
        action={
          <Button onClick={onClose} color="inherit">
            I Understand
          </Button>
        }
      >
        <AlertTitle>Warning</AlertTitle>
        We observed that you moved out of test screen. You will{" "}
        <strong>not</strong> be allowed to continue if we detect it again.
      </Alert>
    </RawPopup>
  );
};

export default CheatingPopup;
