import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { teal } from "@mui/material/colors";
import React, { useContext } from "react";
import { AuthContext } from "../../App";
import Logo from "./Logo";
import Profile from "./Profile";

export const MenuButton = ({ onClick }) => {
  return (
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ mr: 2 }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
  );
};

const NavBar = React.forwardRef(({ toggleDrawer }, ref) => {
  const {
    state: { isFullScreen },
  } = useContext(AuthContext);

  return (
    <Box sx={{ flexGrow: 1, display: isFullScreen ? "none" : "block" }}>
      <AppBar
        ref={ref}
        position="fixed"
        sx={{
          backgroundColor: teal["400"],
          color: "black",
        }}
      >
        <Toolbar>
          <MenuButton onClick={toggleDrawer} />
          <Logo />
          <Profile />
        </Toolbar>
      </AppBar>
    </Box>
  );
});

export default NavBar;
