import { Box, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import React, { useEffect, useState } from "react";
dayjs.extend(duration);

const Timer = ({ startTime, duration, onEnd }) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      let secondsLeft =
        duration * 60 - dayjs().diff(dayjs(startTime), "seconds");
      if (secondsLeft <= 0) {
        secondsLeft = 0;
        onEnd();
      }
      setTimeLeft(secondsLeft);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [timeLeft]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", pl: 1, pr: 1 }}>
      <Typography variant="body1">Time left: </Typography>
      <Typography
        variant="body1"
        fontWeight="500"
        color={timeLeft < 5 * 60 ? red[700] : grey[900]}
      >
        {dayjs.duration(timeLeft * 1000).format("HH:mm:ss")}
      </Typography>
    </Box>
  );
};

export default Timer;
