import {
  CircularProgress,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  getNextQuestion,
  getPreviousQuestion,
  getQuestionAtIndex,
  getTestData,
  markQuestion,
  recordTimeTakenForQuestion,
  sendCheating,
  sendSelectedResponse,
  submitSection,
  submitTest,
  unmarkQuestion,
  uploadImage,
} from "../actions/test";
import TestInstructions from "../components/Instructions/TestInstructions";
import routes from "../components/Layout/routes";
import SubmittingScreen from "../components/Message/SubmittingScreen";
import CheatingPopup from "../components/Popups/cheating";
import Question from "../components/Question";
import QuestionGrid from "../components/QuestionGrid";
import TestFooter from "../components/TestFooter";
import TestHeader from "../components/TestHeader";
import TestVideoPreview from "../components/TestVideo/Preview";
import FloatingVideo from "../components/TestVideo/Video";
import { EXAM_STATUS, RECORDING_STATUS } from "../utils/constants";
import { usePrevious } from "../utils/hooks";
import "./styles.css";

export const TestError = ({ message, time = 5 }) => {
  const navigate = useNavigate();
  const [redirectSeconds, setRedirectSeconds] = useState(time);

  useEffect(() => {
    const interval = setInterval(() => {
      setRedirectSeconds(redirectSeconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [redirectSeconds]);

  if (redirectSeconds <= 0) {
    navigate(routes.home.path);
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" component="div">
        {message}
      </Typography>
      <Typography variant="body1" component="div">
        Redirecting to{" "}
        <Link href={routes.home.path} underline="hover">
          home page
        </Link>{" "}
        in {redirectSeconds} seconds
      </Typography>
    </Box>
  );
};

const TakeTest = () => {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const { id: testId } = useParams();
  const [loading, setLoading] = useState(false);
  const [test, setTest] = useState({});
  const [showQuestionGrid, setShowQuestionGrid] = useState(false);
  const [question, setQuestion] = useState(null);
  const [startTimeOfUser, setStartTimeOfUser] = useState(null);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [markedQuestions, setMarkedQuestions] = useState(new Set());
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
  const [questionIds, setQuestionIds] = useState([]);
  const [section, setSection] = useState({});
  const [sectionRestrictions, setSectionRestrictions] = useState({});
  const [error, setError] = useState(null);
  const [cheatingPopup, setCheatingPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [recordingStatus, setRecordingStatus] = useState(
    RECORDING_STATUS.INITIATED
  );
  const [examStatus, setExamStatus] = useState(EXAM_STATUS.VIDEO_PREVIEW);

  let startTime = useRef(null);
  const previousQuestion = usePrevious(question);
  let streamRecorder = null;
  const [webcamStream, setWebcamStream] = useState(null);

  useEffect(() => {
    getCameraAccess();

    return () => {
      console.log(webcamStream?.getTracks());
      webcamStream?.getTracks().forEach((track) => track.stop());
    };
  }, []);

  useEffect(() => {
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  useEffect(() => {
    if (previousQuestion && question && startTime) {
      const time = Date.now() - startTime.current;
      console.log(time, previousQuestion?._id);
      // send time taken for this question to server
      recordTimeTakenForQuestion(testId, previousQuestion?._id, time);
    }
    setSelectedOption(question?.lastSelectedOptionId);
    console.log("changed", question?._id);
    startTime.current = new Date();
    return () => {
      console.log("changed", question?._id);
    };
  }, [question?._id]);

  const getCameraAccess = async () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { audio: true, video: true },
        function (stream) {
          setWebcamStream(stream);
          setRecordingStatus(RECORDING_STATUS.ALLOWED);
        },
        function (err) {
          console.log("The following error occurred: " + err.name);
          setRecordingStatus(RECORDING_STATUS.NOT_ALLOWED);
        }
      );
    } else {
      setRecordingStatus(RECORDING_STATUS.NOT_SUPPORTED);
      return false;
    }
  };

  const uploadVideo = async () => {
    streamRecorder.getRecordedData((blob) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        console.log(base64data);
      };
    });
  };

  const takePicture = (video) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 640;
    canvas.height = 480;
    context.drawImage(video, 0, 0, 640, 480);
    canvas.toBlob((blob) => {
      let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });
      uploadImage({ testId, image: file });
    }, "image/jpeg");
    setTimeout(() => {
      takePicture(video);
    }, 3000);
  };

  const showSubmittingScreen = () => {
    setSubmitting(true);
  };

  const hideSubmittingScreen = () => {
    setSubmitting(false);
  };

  const onBlur = async () => {
    console.log("blur");
    const res = await sendCheating(testId, "moved out of screen");
    if (res.cheatingCount > res.maxAllowed) {
      onFinalSubmit();
      setError({
        message:
          "You have been marked as cheating. Your test has been submitted.",
        showTime: 10,
      });
    } else {
      showCheatingPopup();
    }
  };

  const init = async () => {
    setLoading(true);
    try {
      const {
        test,
        totalQuestions,
        question,
        lastSelectedOptionId,
        marked,
        flags,
        questionIds,
        section,
        questionIndex = 0,
      } = await getTestData(testId);
      setTest(test);
      setSection(section);
      setExamStatus(EXAM_STATUS.INSTRUCTIONS);
      setStartTimeOfUser(section.startTimeOfUser);
      setTotalQuestionsCount(totalQuestions);
      setQuestion({ ...question, lastSelectedOptionId, marked });
      setMarkedQuestions(new Set(flags.marked));
      setAnsweredQuestions(new Set(flags.answered));
      setQuestionIds(questionIds);
      setSectionRestrictions(section.restrictions);
      setCurrentQuestion(questionIndex);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onPrev = async () => {
    if (currentQuestion === 0) {
      return;
    }
    setLoading(true);
    setCurrentQuestion(currentQuestion - 1);

    const { question, lastSelectedOptionId, marked } =
      await getPreviousQuestion(testId);
    setQuestion({ ...question, lastSelectedOptionId, marked });
    setLoading(false);
  };

  const onNext = async () => {
    if (currentQuestion === totalQuestionsCount - 1) {
      return;
    }
    setLoading(true);
    setCurrentQuestion(currentQuestion + 1);
    const { question, lastSelectedOptionId, marked } = await getNextQuestion(
      testId
    );
    setQuestion({ ...question, lastSelectedOptionId, marked });
    setLoading(false);
  };

  const onSubmit = useCallback(async () => {
    // call server
    showSubmittingScreen();
    const time = Date.now() - startTime.current;
    recordTimeTakenForQuestion(testId, question?._id, time);
    await submitSection(testId);
    hideSubmittingScreen();
    init();
  }, [testId, question, startTime.current]);

  const onFinalSubmit = useCallback(async () => {
    // call server
    showSubmittingScreen();
    const time = Date.now() - startTime.current;
    recordTimeTakenForQuestion(testId, question?._id, time);
    await submitTest(testId);
    // uploadVideo();
    navigate(routes.home.path);
  }, [testId, question, startTime.current]);

  const onMark = async () => {
    const questionId = question._id;
    if (markedQuestions.has(questionId)) {
      await unmarkQuestion({ testId, questionId });
      const updatedMarkedQuestions = new Set([...markedQuestions]);
      updatedMarkedQuestions.delete(questionId);
      setMarkedQuestions(new Set([...updatedMarkedQuestions]));
      setQuestion({ ...question, marked: false });
    } else {
      await markQuestion({ testId, questionId });
      const updatedMarkedQuestions = new Set([...markedQuestions]);
      updatedMarkedQuestions.add(questionId);
      setMarkedQuestions(new Set([...updatedMarkedQuestions]));
      setQuestion({ ...question, marked: true });
    }
  };

  const onSelectOption = async (optionId) => {
    const questionId = question._id;
    setSelectedOption(optionId);

    if (optionId) {
      setAnsweredQuestions(new Set([...answeredQuestions, questionId]));
    } else {
      setAnsweredQuestions(
        new Set([...answeredQuestions].filter((id) => id !== questionId))
      );
    }

    try {
      await sendSelectedResponse({
        testId,
        questionId,
        optionId,
      });
    } catch (err) {
      console.error(err);
      window.alert("Failed to submit response");
    }
  };

  const changeQuestion = async (index) => {
    setLoading(true);
    const { question, lastSelectedOptionId, marked } = await getQuestionAtIndex(
      testId,
      index
    );
    setCurrentQuestion(index);
    setQuestion({ ...question, lastSelectedOptionId, marked });
    setLoading(false);
  };

  const showCheatingPopup = () => {
    setCheatingPopup(true);
  };

  const hideInstructionAndContinue = () => {
    startTime.current = new Date();
    setExamStatus(EXAM_STATUS.TEST);
    // streamRecorder = webcamStream.record();
  };

  if (submitting) <SubmittingScreen />;
  if (error)
    return <TestError message={error.message} time={error.showTime || 5} />;
  if (examStatus === EXAM_STATUS.INSTRUCTIONS) {
    return (
      <TestInstructions
        section={section}
        onContinue={hideInstructionAndContinue}
      />
    );
  }
  if (examStatus === EXAM_STATUS.VIDEO_PREVIEW) {
    return (
      <TestVideoPreview
        stream={webcamStream}
        status={recordingStatus}
        onContinue={init}
        onRetry={getCameraAccess}
      />
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
    >
      <CheatingPopup
        open={cheatingPopup}
        onClose={() => setCheatingPopup(false)}
      />
      <TestHeader
        title={test.title}
        subTitle={section.title}
        startTime={startTimeOfUser}
        duration={section.duration}
        onEnd={onFinalSubmit}
        onSubmit={onSubmit}
        questionNumber={currentQuestion + 1}
        totalQuestions={totalQuestionsCount}
        onShowQuestionGrid={() => setShowQuestionGrid(true)}
      />
      <Divider />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : !question ? null : (
        <div className="test-outer-container">
          <div className="test-left-container">
            <div className="test-left-inner-container">
              <Question
                selectedOption={selectedOption}
                obj={question}
                onMark={onMark}
                onSelect={onSelectOption}
                marked={question.marked}
              />
            </div>
            <TestFooter
              onPrev={
                currentQuestion !== 0 &&
                sectionRestrictions.allowQuestionSwitching
                  ? onPrev
                  : null
              }
              onNext={
                currentQuestion === totalQuestionsCount - 1 ? null : onNext
              }
              onClearResponse={
                selectedOption ? () => onSelectOption(null) : null
              }
            />
          </div>
          <Drawer
            anchor={"right"}
            open={showQuestionGrid}
            onClose={() => setShowQuestionGrid(false)}
          >
            <Box sx={{ width: 300, p: 4 }}>
              <QuestionGrid
                questionIds={questionIds}
                questionsCount={totalQuestionsCount}
                onQuestionClick={
                  sectionRestrictions.allowQuestionSwitching
                    ? changeQuestion
                    : () => {}
                }
                currentQuestion={currentQuestion}
                answeredQuestions={answeredQuestions}
                markedQuestions={markedQuestions}
              />
            </Box>
          </Drawer>
        </div>
      )}
      <FloatingVideo stream={webcamStream} onLoad={takePicture} />
    </Box>
  );
};
export default TakeTest;
