import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import TopicsWiseScore from "./TopicsWiseScore";
import TopicWiseAggregate from "./TopicWiseAggregate";

const tabs = [
  {
    title: "Aggregate",
    Component: TopicWiseAggregate,
  },
  {
    title: "Topics",
    Component: TopicsWiseScore,
  },
];

const TestAnalytics = () => {
  const [currentTab, setCurrentTab] = useState(tabs[0].title);

  const changeTab = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Box sx={{ width: "80%", ml: "auto", mr: "auto" }}>
      <TabContext value={currentTab}>
        <TabList onChange={changeTab} aria-label="Test analytics tabs">
          {tabs?.map(({ title }) => (
            <Tab key={title} label={title} value={title} />
          ))}
        </TabList>
        <Box sx={{ overflowY: "auto" }}>
          {tabs?.map(({ Component, title }) => (
            <TabPanel key={title} value={title}>
              <Component />
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

export default TestAnalytics;
