import dayjs from "dayjs";

export const getCodingQuestionResponse = (responses) => {
  const sortedResponses = responses.sort((a, b) =>
    dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1
  ); // sorted by createdAt with most recent submission at index 0

  let finalResponse;
  const submittedResponses = sortedResponses.filter((r) => r.submitted);
  if (submittedResponses.length === 0) {
    finalResponse = sortedResponses[0];
  } else {
    finalResponse = submittedResponses[0];
  }
  return finalResponse;
};

export const getMCQQuestionResponse = (responses) => {
  return responses?.sort((a, b) =>
    dayjs(a.createdAt) > dayjs(b.createdAt) ? -1 : 1
  )[0];
};
