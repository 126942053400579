import { io } from "socket.io-client";
import { getCookie } from "./cookie";
import { redirect } from "./redirect";

global.socket = null;

export const getSocket = () => {
  if (global.socket) return global.socket;

  const socket = io(process.env.REACT_APP_TEST_BACKEND_URL, {
    auth: {
      token: getCookie("token"),
    },
  });

  socket.on("connect", () => {
    console.log("Server connected");
  });
  socket.on("connect_error", (error) => {
    if (error.data?.redirectUrl) {
      console.log(error.data);
      redirect(error.data.redirectUrl);
    }
    console.log({ error });
  });

  global.socket = socket;
  return socket;
};

export const emit = (event, data) => {
  const socket = getSocket();
  return new Promise((resolve, reject) => {
    socket.emit(event, data, (err, response) => {
      if (err) {
        console.log(err);
        reject(err);
        return;
      }
      resolve(response);
    });
  });
};
