import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CardContent,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  FormControl,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { cpp } from "@codemirror/lang-cpp";
import "codemirror/keymap/sublime";
import "codemirror/theme/monokai.css";
import "codemirror/mode/python/python";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/clike/clike";
import { sendTestCode, sendCodeSubmission } from "../../actions/test";
import { useParams } from "react-router";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { red, yellow } from "@mui/material/colors";

import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another

// const languageModes = {
//   javascript: { name: "javascript", extensions: [javascript({ jsx: true })] },
//   python: { name: "python", version: 3, extensions: [python()] },
//   cpp: { name: "cpp", extensions: [cpp()] },
// };

const languageModes = {
  javascript: { name: "Javascript", mode: languages.javascript },
  python: { name: "Python", mode: languages.python },
  clike: { name: "C/C++", mode: languages.clike },
};

const CodeQuestion = ({ obj, marked, onMark }) => {
  const { id: testId } = useParams();

  const [codesOfLanguages, setCodesOfLanguages] = useState({});
  const [code, setCode] = useState("");
  const [languageMode, setLanguageMode] = useState(languageModes.javascript);
  const [testCasesResult, setTestCasesResult] = useState([]);

  const testCode = async () => {
    const res = await sendTestCode({
      testId,
      questionId: obj._id,
      code,
      language: languageMode.name,
    });
    setTestCasesResult(res.testCasesResult);
  };

  const submitCode = async () => {
    const res = await sendCodeSubmission({
      testId,
      questionId: obj._id,
      code,
      language: languageMode.name,
    });
    setTestCasesResult(res.testCasesResult);
  };

  useEffect(() => {
    if (obj) {
      setTestCasesResult(
        Array.from({ length: obj.testCasesLength }, (_) => null)
      );
    }
  }, [obj]);

  useEffect(() => {
    setCodesOfLanguages({ ...codesOfLanguages, [languageMode.name]: code });
  }, [code]);

  useEffect(() => {
    setCode(codesOfLanguages[languageMode.name]);
  }, [languageMode]);

  console.log({ testCasesResult });
  return (
    <CardContent sx={{ display: "flex" }}>
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          flexDirection: "row",
          flex: 1,
          overflowY: "auto",
        }}
      >
        {/* <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: "400", fontSize: 18 }}
        >
          {obj.text}
        </Typography> */}
        <div
          style={{ height: "auto", width: "100%" }}
          className="test-instructions"
          dangerouslySetInnerHTML={{ __html: obj.text }}
        />
        <Box sx={{ marginLeft: "auto" }}>
          <Tooltip title={marked ? "Unmark" : "Mark for Later"}>
            <IconButton aria-label="flag" onClick={onMark}>
              <FlagIcon
                sx={
                  marked
                    ? { fill: "green", strokeWidth: 1, stroke: "green" }
                    : { fill: "white", stroke: "grey", strokeWidth: "2px" }
                }
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ flex: 1, width: "100%", height: "100%" }}>
        <FormControl>
          <InputLabel id="test-code-language-label">Language</InputLabel>
          <Select
            value={languageMode}
            labelId="test-code-language-label"
            id="test-code-language"
            label="Language"
            onChange={(e) => setLanguageMode(e.target.value)}
          >
            {Object.keys(languageModes).map((key) => (
              <MenuItem
                key={languageModes[key].name}
                value={languageModes[key]}
              >
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <CodeMirror
          value={code}
          height="400px"
          extensions={languageMode.extensions}
          onChange={(value, viewUpdate) => {
            console.log("value:", value);
            setCode(value);
          }}
          options={{ theme: "monokai", keyMap: "sublime", mode: languageMode }}
        /> */}
        <Editor
          value={code}
          onValueChange={(code) => setCode(code)}
          highlight={(code) => highlight(code, languageMode.mode)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 12,
          }}
        />
        <Button onClick={testCode}>Run</Button>
        <Button onClick={submitCode}>Submit</Button>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ mt: 4 }} variant="h6" component="div">
            Test cases
          </Typography>
          <List>
            {testCasesResult.map((tc, index) => {
              console.log(tc);
              return (
                <ListItem>
                  <ListItemIcon>
                    {tc === null ? (
                      <CircleOutlinedIcon sx={{ color: yellow[600] }} />
                    ) : tc ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <CancelIcon sx={{ color: red[500] }} />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={`Test Case ${index + 1}`} />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </CardContent>
  );
};

export default CodeQuestion;
