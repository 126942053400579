import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { getAllTests, getScores } from "../actions/test";
import TestCard, {
  TestCardActions,
  TestSectionsTimeline,
} from "../components/TestCard";
import EmptyTests from "../components/TestCard/empty";
import TestListFilters from "../components/TestCard/TestListFilters";
import { initialFilters, testStatusGroup } from "../utils/constants";
import { convertToMap } from "../utils/object";
import CloseIcon from "@mui/icons-material/Close";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [tests, setTests] = useState({});
  const [filters, setFilters] = useState(initialFilters);
  const [selectedTest, setSelectedTest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { tests } = await getAllTests();
    setTests(convertToMap(tests, "_id"));
    setLoading(false);
    const { scores } = await getScores();
    setTests((tests) => {
      const newTests = { ...tests };
      Object.values(scores).forEach((score) => {
        newTests[score.testId].score = score.score;
      });
      return newTests;
    });
  };

  const changeFilters = (changedFilter) => {
    setFilters({ ...filters, ...changedFilter });
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  const showTest = (testId) => {
    setSelectedTest(testId);
    setDialogOpen(true);
  };

  const filteredTests = Object.values(tests).filter((t) => {
    if (
      (filters.title &&
        !t.title.toLowerCase().includes(filters.title.toLowerCase())) ||
      (testStatusGroup[filters.testStatus] !== "*" &&
        !testStatusGroup[filters.testStatus].includes(
          t.status.toUpperCase()
        )) ||
      (filters.date && !dayjs(t.startTime).isSame(dayjs(filters.date), "date"))
    ) {
      return false;
    }
    return t;
  });

  const sortedTests = filteredTests.sort((a, b) =>
    dayjs(a.startTime).isBefore(dayjs(b.startTime)) ? 1 : -1
  );

  return (
    <Box
      sx={{
        width: "80%",
        height: "100%",
        padding: 2,
        flexGrow: 1,
        flexDirection: "column",
        overflow: "auto",
        alignSelf: "center",
        alignItems: "center",
        display: "flex",
        margin: "auto",
      }}
    >
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        scroll={"paper"}
        maxWidth={"md"}
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 1,
            pl: 3,
          }}
        >
          {tests[selectedTest]?.title}
          <IconButton aria-label="close" onClick={() => setDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{
            height: "60vh",
          }}
        >
          <Typography
            sx={{ mt: 1 }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Sections:
          </Typography>
          <TestSectionsTimeline obj={tests[selectedTest]} />
        </DialogContent>
        <DialogActions>
          <TestCardActions obj={tests[selectedTest]} />
        </DialogActions>
      </Dialog>
      {loading ? (
        <CircularProgress />
      ) : tests.length === 0 ? (
        <EmptyTests />
      ) : (
        <>
          <TestListFilters
            filters={filters}
            onChange={changeFilters}
            onReset={resetFilters}
          />
          <Typography variant="h4" component="div">
            Tests
          </Typography>
          {filteredTests.length === 0 ? (
            <EmptyTests message="No tests found for the applied filters" />
          ) : (
            <Grid
              container
              spacing={0}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {sortedTests.map((t) => (
                <Grid item key={t._id} xs={12} s={6} md={6} lg={4} xl={3}>
                  <TestCard obj={t} onExpand={() => showTest(t._id)} />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default Home;
