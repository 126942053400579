import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, CircularProgress, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getTestReport } from "../../actions/report";
import { QuestionTypes, testTypes } from "../../utils/constants";
import { convertToMap } from "../../utils/object";
import {
  getCodingQuestionResponse,
  getMCQQuestionResponse,
} from "../../utils/responses";
import TestSectionReport from "./TestSectionReport";

const calculateScore = (sections, responses) => {
  let score = 0;
  const questions = {};
  sections.forEach((section) => {
    section.questions.forEach((question) => {
      questions[question._id] = question;
    });
  });

  Object.keys(responses).forEach((questionId) => {
    const question = questions[questionId];
    switch (question.type) {
      case QuestionTypes.MCQ: {
        const finalResponse = getMCQQuestionResponse(responses[questionId]);
        const correctOption = question.options.find((opt) => opt.isCorrect);
        if (finalResponse.optionId === correctOption._id) score++;
        break;
      }
      case QuestionTypes.Code:
        {
          const finalResponse = getCodingQuestionResponse(
            responses[questionId]
          );
          score += finalResponse
            ? finalResponse.testCasesResult.filter((testCase) => testCase)
                .length / finalResponse.testCasesResult.length
            : 0;
        }
        break;
      default:
        break;
    }
  });
  return `${score} / ${Object.keys(questions).length}`;
};

const QuestionsReport = ({ test, setTest, setError }) => {
  const { id } = useParams();
  const [responses, setResponses] = useState({});
  const [report, setReport] = useState({});
  const [currentSection, setCurrentSection] = useState(null);
  const [timeAnalytics, setTimeAnalytics] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getTestReport(id)
      .then((res) => {
        const { test, responses, report, analytics } = res;
        setTest(test);
        setResponses(responses);
        setTimeAnalytics(convertToMap(analytics.analytics, "questionId"));
        setReport(convertToMap(report.report, "questionId"));
        setCurrentSection(test.sections[0]._id);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const changeSection = (event, value) => {
    setCurrentSection(value);
  };

  return loading ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ width: "100%", ml: "auto", mr: "auto" }}>
      <Typography variant="h6" component="div">
        Score: {calculateScore(test.sections, responses)}
      </Typography>
      <TabContext value={currentSection}>
        <TabList onChange={changeSection} aria-label="Test sections tabs">
          {test.sections?.map((section) => (
            <Tab key={section._id} label={section.title} value={section._id} />
          ))}
        </TabList>
        <Box sx={{ overflowY: "auto" }}>
          {test.sections?.map((section) => (
            <TabPanel key={section._id} value={section._id}>
              <TestSectionReport
                hideQueAnalytics={[
                  testTypes.PRACTICE,
                  testTypes.PREPARATION_PRACTICE,
                ].includes(test.testType)}
                section={section}
                responses={responses}
                report={report}
                timeAnalytics={timeAnalytics}
              />
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  );
};

export default QuestionsReport;
