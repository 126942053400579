import { emit, getSocket } from "../utils/socketio";

export const getAllTests = () => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getAllTests", {}, (err, data) => {
      if (err) {
        console.log(err);
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const getScores = () => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getScores", {}, (err, data) => {
      if (err) {
        console.log(err);
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const getTestData = (testId) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getTestData", { testId }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const getPreviousQuestion = (testId) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getPreviousQuestion", { testId }, (err, question) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(question);
    });
  });
};

export const getNextQuestion = (testId) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getNextQuestion", { testId }, (err, question) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(question);
    });
  });
};

export const getQuestionAtIndex = (testId, index) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("getQuestionAtIndex", { index, testId }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const sendSelectedResponse = ({ testId, questionId, optionId }) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit(
      "addResponse",
      { testId, questionId, optionId },
      (err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      }
    );
  });
};

export const sendTestCode = ({ testId, questionId, code, language }) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit(
      "testCode",
      { testId, questionId, code, language },
      (err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      }
    );
  });
};

export const sendCodeSubmission = ({ testId, questionId, code, language }) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit(
      "addCodeResponse",
      { testId, questionId, code, language },
      (err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      }
    );
  });
};

export const markQuestion = ({ testId, questionId }) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("markQuestion", { testId, questionId }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const unmarkQuestion = ({ testId, questionId }) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("unmarkQuestion", { testId, questionId }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const submitSection = (testId) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("submitSection", { testId }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const submitTest = (testId) => {
  return emit("submitTest", { testId });
};

export const generateTestQuestions = (testId) => {
  return new Promise((resolve, reject) => {
    const socket = getSocket();
    socket.emit("generateTestQuestions", { testId }, (err, data) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(data);
    });
  });
};

export const sendCheating = async (testId, reason) => {
  const res = await emit("cheating", { testId, reason });
  return res;
};

export const recordTimeTakenForQuestion = async (
  testId,
  questionId,
  timeTaken
) => {
  const res = await emit("recordTimeTakenForQuestion", {
    testId,
    questionId,
    timeTaken,
  });
  return res;
};

export const uploadImage = async (data) => {
  const res = await emit("uploadImage", data);
  return res;
};
