import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AuthContext } from "../../App";
import Timer from "../Timer";

const SubmitConfirmationDialog = ({ open, onCancel, onAccept }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm submission?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to submit this section? You will not be able to
          edit your answers after submission.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onAccept} autoFocus>
          Yes, Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TestHeader = ({
  title,
  subTitle,
  startTime,
  duration,
  onEnd,
  onSubmit,
  questionNumber,
  totalQuestions,
  onShowQuestionGrid,
}) => {
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const {
    state: { isFullScreen },
    dispatch,
  } = useContext(AuthContext);

  const showSubmitDialog = () => setSubmitDialogOpen(true);
  const hideSubmitDialog = () => setSubmitDialogOpen(false);
  const handleSubmit = () => {
    onSubmit();
    hideSubmitDialog();
  };

  const setIsFullScreen = (value) => {
    dispatch({
      type: "TOGGLE_FULLSCREEN",
      payload: {
        isFullScreen: value,
      },
    });
  };

  /* View in fullscreen */
  function openFullscreen() {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    } else {
      return;
    }

    setIsFullScreen(true);
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    } else {
      return;
    }

    setIsFullScreen(false);
  }

  return (
    <Box sx={{ width: "auto", display: "flex", flexDirection: "column" }}>
      <SubmitConfirmationDialog
        open={submitDialogOpen}
        onCancel={hideSubmitDialog}
        onAccept={handleSubmit}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Timer startTime={startTime} duration={duration} onEnd={onEnd} />
        <Box>
          <Button
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={isFullScreen ? closeFullscreen : openFullscreen}
          >
            {isFullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ ml: 2 }}
            onClick={showSubmitDialog}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          px: 2,
          py: 1.5,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="subtitle1">Section: </Typography>
          <Typography variant="subtitle1" fontWeight="500">
            {subTitle}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 2,
          py: 1.5,
        }}
      >
        <Typography variant="subtitle1" fontWeight="500">
          Question {questionNumber} / {totalQuestions}:
        </Typography>
        <Button variant="outlined" onClick={onShowQuestionGrid}>
          Show Question Grid
        </Button>
      </Box>
    </Box>
  );
};

export default TestHeader;
